$ln-webpack: true;

$use-ln-alert: true;

@import '@jsluna/style/scss/main';

.DateInput {

  width: 275px;
}

/* Removes padding to have more visible rows in the table */
.storeItemTable td {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 1;
}

.ln-c-form-group {
  margin-bottom: var(--ln-space-spacing-x1);
  position: relative;
}

// freeze header
.freezeHeader table {
  text-align: center;
  position: relative;
  border-collapse: collapse;
  }
.freezeHeader th {
  background: white;
  text-align: center;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.freezeHeader td {
  text-align: center;
}
